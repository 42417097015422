import {ref, watch, computed, reactive} from '@vue/composition-api'
import store from '@/store'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function oneContractPurchase(config) {
  const toast = useToast()
  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'contractNo', label: '合同编号' },
    { key: 'contractTitle', label: '合同名称' },
    { key: 'applyName', label: '申请人' },
    { key: 'ourCorporationName', label: '我方单位名称' },
    { key: 'theirCorporationName', label: '对方单位名称' },
    { key: 'ourCorporationChargerName', label: '我方负责人' },
    { key: 'contractType', label: '合同类型' },
    { key: 'validDateEnd', label: '协议有效期至' },
    { key: 'contractStatus', label: '合同状态' },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const orderBy = ref("")
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([start, limit], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    const condition = {...store.getters['oneContractPurchaseList/getCondition']}
    store
        .dispatch('oneContractPurchaseList/search', {
          ourCorporationCode: condition.ourCorporationId == undefined?null:condition.ourCorporationId.id,
          theirCorporationId: condition.theirCorporationId == undefined?null:condition.theirCorporationId.id,
          current: start.value,
          size: limit.value,
          order_by: orderBy.value,
          order_desc: isSortDirDesc.value === true ? 'desc':'',
        })
        .then(response => {
          const data = response.data.data
          const list = data.ext.jsonNode.records
          for(let i=0;i<list.length;i++){
            if(list[i].contractStatus == 'N'){
              list[i].contractStatus = '新建'
            }else if(list[i].contractStatus == 'O'){
              list[i].contractStatus = '已作废'
            }else if(list[i].contractStatus == 'RUN'){
              list[i].contractStatus = '协同中'
            }else if(list[i].contractStatus == 'P'){
              list[i].contractStatus = '审核中'
            }else if(list[i].contractStatus == 'A'){
              list[i].contractStatus = '已批准'
            }else if(list[i].contractStatus == 'R'){
              list[i].contractStatus = '已拒绝'
            }else if(list[i].contractStatus == 'S'){
              list[i].contractStatus = '已签订'
            }else if(list[i].contractStatus == 'C'){
              list[i].contractStatus = '已取消'
            }else if(list[i].contractStatus == 'D'){
              list[i].contractStatus = '已暂停'
            }else if(list[i].contractStatus == 'H'){
              list[i].contractStatus = '用章驳回'
            }else if(list[i].contractStatus == 'F'){
              list[i].contractStatus = '已终止'
            }else if(list[i].contractStatus == 'E'){
              list[i].contractStatus = '已结束'
            }else if(list[i].contractStatus == 'B'){
              list[i].contractStatus = '待申请签章'
            }else if(list[i].contractStatus == 'G'){
              list[i].contractStatus = '用章批准'
            }else if(list[i].contractStatus == 'SIGNING'){
              list[i].contractStatus = '签署中'
            }else if(list[i].contractStatus == 'AR'){
              list[i].contractStatus = '已归档'
            }else if(list[i].contractStatus == 'DI'){
              list[i].contractStatus = '已解除'
            }else if(list[i].contractStatus == 'OTHER'){
              list[i].contractStatus = '其他'
            }else if(list[i].contractStatus == 'WAIT_SUBMIT'){
              list[i].contractStatus = '待提交'
            }else if(list[i].contractStatus == 'CLOSED'){
              list[i].contractStatus = '已关闭'
            }
          }
          listTotals.value = data.ext.jsonNode.total
          callback(list)
        })
        .catch((e) => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'ONE采购合同合同列表获取错误',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
  }

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    orderBy,
    isSortDirDesc,
    refListTable,
    refetchData,

  }
}
