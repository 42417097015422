<template>
  <div>
    <b-card>
      <div>
        <b-row>
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

        </b-row>
        <b-form
            @submit.prevent="searchByCondition"
            @reset.prevent="resetCondition"
        >
          <b-card>
            <b-row>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="4"
                    label-cols-lg="4"
                    label="我方单位:"
                    label-for="ourCorporationName"
                    label-size="sm"
                >
                  <v-select
                      id="ourCorporationName"
                      label="title"
                      :options="ourCorporations"
                      v-model="condition.ourCorporationId"
                      class="select-size-sm"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="4"
                    label-cols-lg="4"
                    label="对方单位:"
                    label-for="theirCorporationName"
                    label-size="sm"
                >
                  <v-select
                      id="theirCorporationName"
                      label="title"
                      :options="theirCorporations"
                      v-model="condition.theirCorporationId"
                      class="select-size-sm"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="3"
              >
                <b-button
                    variant="outline-primary"
                    type="submit"
                    class="mr-1"
                >
                  <span class="align-right">查询</span>
                </b-button>

              </b-col>
            </b-row>
          </b-card>
        </b-form>
      </div>
      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          :fields="tableColumns"
          primary-key="invoice_id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
          v-model="table"
      >

        <!-- Columns -->
        <template #cell(contractNo)="data">
          {{data.item.contractNo}}
        </template>
        <template #cell(contractTitle)="data">
<!--          <a target="_blank" :href="toURL(data.item.contractUuid)">
            {{data.item.contractTitle}}
          </a>-->
          {{data.item.contractTitle}}
        </template>
        <template #cell(applyName)="data">
          {{data.item.applyName}}
        </template>
        <template #cell(ourCorporationName)="data">
          {{data.item.ourCorporationName}}
        </template>
        <template #cell(theirCorporationName)="data">
          {{data.item.theirCorporationName}}
        </template>
        <template #cell(ourCorporationChargerName)="data">
          {{data.item.ourCorporationChargerName}}
        </template>
        <template #cell(contractType)="data">
          {{data.item.contractType}}
        </template>
        <template #cell(validDateEnd)="data">
          {{data.item.validDateEnd}}
        </template>
        <template #cell(contractStatus)="data">
          {{data.item.contractStatus}}
        </template>


      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BFormCheckbox, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BModal
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import Ripple from "vue-ripple-directive";
import {ref, onUnmounted, reactive, onMounted, toRefs} from '@vue/composition-api'
import { toDate,avatarText, getCode, getCodeColor, getCodeLabel, getCodeOptions,isEmpty } from '@core/utils/filter'
import oneContractPurchaseStore from "@/views/apps/onecontractpurchase/oneContractPurchaseStore";
import oneContractPurchase from './oneContractPurchase.js'
import XyInputButton from "@/views/components/xy/XyInputButton";
import {useToast} from "vue-toastification/composition";

export default {
  components: {
    BCard,
    BModal,
    BRow,
    BCol,
    BFormInput,
    BFormCheckbox,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    XyInputButton,
  },
  data() {
    return {
      /*contract_status: [
        {label: '新建', value: 0},
        {label: '已作废', value: 7},
        {label: '协同中', value: 8},
        {label: '审核中', value: 9},
        {label: '已批准', value: 10},
        {label: '已拒绝', value: 11},
        {label: '已签订', value: 12},
        {label: '已取消', value: 13},
        {label: '已暂停', value: 14},
        {label: '用章驳回', value: 15},
        {label: '已终止', value: 16},
        {label: '已结束', value: 17},
        {label: '待申请签章', value: 18},
        {label: '用章批准', value: 19},
        {label: '签署中', value: 20},
        {label: '已归档', value: 21},
        {label: '已解除', value: 22},
        {label: '其他', value: 23},
        {label: '待提交', value: 24},
        {label: '已关闭', value: 25},
      ],*/

      ourCorporations:[],
      theirCorporations:[],
    }
  },
  methods: {
    searchByCondition(){
      this.refetchData()
    },
    resetCondition() {
      this.state.condition = {}
      store.commit('oneContractPurchaseList/updateCondition', this.state.condition)
      this.refetchData()
    },
  },
  directives: {
    Ripple,
  },
  setup(props) {
    const toast = useToast()

    const state = reactive({
      condition: {},
    })
    // Register module
    if (!store.hasModule('oneContractPurchaseList')) store.registerModule('oneContractPurchaseList', oneContractPurchaseStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('oneContractPurchaseList')) store.unregisterModule('oneContractPurchaseList')
    })
    onMounted(() => {
      state.condition = store.getters['oneContractPurchaseList/getCondition']
    })

    /*const toURL= function (urlId) {
      return process.env.VUE_APP_CONTRACT_DETAIL+"/"+urlId
    }

    const addPage = function () {
      window.open(process.env.VUE_APP_CONTRACT_ADD)
    }*/

    const edit = function () {
      store.dispatch('oneContractPurchaseList/initPage').then(res => {
        let ourCompanyList = res.data.data.ext.ourCompanyList
        let theirCompanyPurchaseList = res.data.data.ext.theirCompanyPurchaseList
        for(let i=0;i<ourCompanyList.length;i++){
          this.ourCorporations.push({"title":ourCompanyList[i].company_name,"id":ourCompanyList[i].zt_company_id});
        }
        for(let i=0;i<theirCompanyPurchaseList.length;i++){
          this.theirCorporations.push({"title":theirCompanyPurchaseList[i].company_name,"id":theirCompanyPurchaseList[i].zt_company_id});
        }
      })

    }

    const {
      searchList,
      table,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,


    } = oneContractPurchase(
        {}
    )

    return {
      ...toRefs(state),
      state,
      searchList,
      /*toURL,
      addPage,*/
      table,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      edit,

      // Filter
      avatarText,
      getCodeLabel,
      getCode,
      toDate,
      getCodeColor,
      getCodeOptions,
      isEmpty,
    }
  },
  created() {
    this.edit()
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
